import classnames from 'classnames';

export const TEXT_BASE_CLASS = {
  h1: 'font-heading font-h1-mobile md:font-h1 text-h1-mobile md:text-h1',
  h2: 'font-heading font-h2-mobile md:font-h2 text-h2-mobile md:text-h2',
  h3: 'font-heading font-h3-mobile md:font-h3 text-h3-mobile md:text-h3',
  h4: 'font-heading font-h4-mobile md:font-h4 text-h4-mobile md:text-h4',
  h5: 'font-heading font-h5-mobile md:font-h5 text-h5-mobile md:text-h5',
  h6: 'font-heading font-h6-mobile md:font-h6 text-h6-mobile md:text-h6',
  body: 'font-text font-body text-body',
  small: 'font-text font-small text-small',
  'body-bold': 'font-text font-body-bold text-body',
  'body-large': 'font-text font-body-large text-body-large',
  'body-large-bold': 'font-text font-body-large-bold text-body-large-bold',
  button: 'font-text font-button text-button uppercase',
  tag: 'font-text font-tag text-tag uppercase',
  pill: 'font-text font-tag text-tag',
  blockquote:
    'font-heading font-h5-mobile md:font-h4 text-h5-mobile md:text-h4 text-violet dd-kr:text-actual-dd-gold dd-en:text-actual-dd-gold dd-kr:font-subheading dd-en:font-subheading dd-kr:text-subheading-mobile dd-en:text-subheading-mobile md:dd-kr:text-subheading md:dd-en:text-subheading',
  'blockquote-body-large':
    'font-text font-body-large text-body-large text-body-text-grey',
  default: 'font-text font-body text-body',
};

export const TAGS = Object.keys(TEXT_BASE_CLASS);

export const getTextClass = ({ type, style, custom }) =>
  classnames(
    'break-words',
    {
      [TEXT_BASE_CLASS[type] ?? TEXT_BASE_CLASS.default]: !custom,
    },
    style
  );

export const getTag = (tag) => {
  const HEADING_TAGS = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
  const STRONG_SUFFIX = 'bold';

  if (HEADING_TAGS.includes(tag)) return tag;
  if (tag.endsWith(STRONG_SUFFIX)) return 'strong';
  return 'span';
};
