import React from 'react';
import PropTypes from 'prop-types';

import { getTag, getTextClass } from './Text.constants';

const Text = ({ tag, children, colour, custom, style }) => {
  const textClass = getTextClass({ custom, type: tag, style });
  const Tag = getTag(tag);
  return (
    <Tag style={{ color: colour }} className={textClass}>
      {children}
    </Tag>
  );
};

export default Text;

Text.propTypes = {
  tag: PropTypes.string,
  // not using baseclass
  custom: PropTypes.bool,
  colour: PropTypes.string,
  style: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
};

Text.defaultProps = {
  tag: 'span',
  style: '',
  custom: false,
};
