import classnames from 'classnames';

import { isDDEnv } from '../../../utils/helper';

import { ctaStyle } from '../../../utils/constants';
import { TEXT_BASE_CLASS } from '../Text/Text.constants';

const BUTTON_BASE_CLASS = {
  Primary: '',
  Secondary:
    'underline underline-offset-[5px] decoration-[1.5px] decoration-violet-bright',
  Tertiary: '',
};

export const BUTTON_VARIANTS = Object.keys(BUTTON_BASE_CLASS);

export const getButtonClassWH = ({ type, invert, style }) => {
  return classnames(
    BUTTON_BASE_CLASS[type] ?? BUTTON_BASE_CLASS.Primary,
    TEXT_BASE_CLASS.button,
    {
      'text-white': invert,
      'decoration-white': invert && type === ctaStyle.SECONDARY,
      'hover:underline hover:underline-offset-[5px] hover:decoration-[1.5px] hover:decoration-dark-warm-grey':
        !invert && type === ctaStyle.PRIMARY,
      'hover:border-b hover:border-b-white': invert,
    },
    style
  );
};

export const getButtonClass = ({ type, invert, style }) => {
  if (isDDEnv()) return style;
  return getButtonClassWH({ type, invert, style });
};
