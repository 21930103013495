import PropTypes from 'prop-types';
import { useContext } from 'react';
import Link from 'next/link';

import { getContent, isWHEnv, isDDEnv } from '../../../utils/helper';

import Icon from '../../atoms/Icon/Icon';
import Text from '../../atoms/Text/Text';

import { normalizedUrl } from './CTA.constants';
import { ICON_POSITION, getIconClass } from '../../atoms/Icon/Icon.constants';
import { getButtonClass } from '../../atoms/Button/Button.constants';
import { CtfCtaStyle } from '../../../utils/constants';
import { StateContext } from '../../../context';

export function CtaWrapper({ url, page, children, classNames, name, onClick }) {
  const { environment } = useContext(StateContext);

  const isLocal = !url?.startsWith('#') && !url?.startsWith('http');
  if (isLocal) {
    url = normalizedUrl(page ? page : url);
    if (environment) {
      url = `/${environment}${url}`;
    }

    return (
      <Link href={url} {...(isDDEnv() && { locale: false })}>
        <a
          className={`aaco-cta inline-flex items-center ${classNames ?? ''}`}
          onClick={(e) => {
            if (onClick) {
              onClick(e, { localUrl: url, name });
            }
          }}
        >
          {children}
        </a>
      </Link>
    );
  }

  const anchor = url?.startsWith('#');
  return (
    <a
      className={`aaco-cta items-center ${classNames ?? ''} flex`}
      href={url}
      target={anchor ? '_self' : ''}
      rel="noopener noreferrer"
      onClick={(e) => {
        if (onClick) {
          onClick(e, { url: !anchor && url, localUrl: anchor && url, name });
        }
      }}
    >
      {children}
    </a>
  );
}

// @TODO: Use button instead of text when design finished
export function Cta({ iconProps, buttonProps, linkProps, onClick }) {
  const { invert, name, type, style: buttonStyle } = buttonProps;
  const { url, page } = linkProps;

  const {
    hasIconBorder,
    iconPosition = ICON_POSITION.right,
    height,
    hideIcon,
    name: icon,
    style: iconStyle,
    width,
  } = iconProps;

  const iconButtonClass = getIconClass({
    icon,
    style: iconStyle,
    iconPosition,
    invert,
    hasIconBorder: isWHEnv() ? true : hasIconBorder,
  });

  const iconTextClass = getButtonClass({
    type,
    invert,
    style: buttonStyle,
  });

  const getCtaWrapperClass = () => {
    let typeClass = '';
    switch (type) {
      case CtfCtaStyle.DD_PRIMARY_FILLED:
        typeClass = 'aaco-cta__primary-filled';
        break;
      case CtfCtaStyle.DD_PRIMARY_LINE:
        typeClass = 'aaco-cta__primary-line';
        break;
      case CtfCtaStyle.DD_SECONDARY:
        typeClass = 'aaco-cta__secondary';
        break;

      case CtfCtaStyle.DD_TERTIARY:
        typeClass = 'aaco-cta__tertiary';
        break;
      default:
        break;
    }
    const invertClass = invert ? 'aaco-cta--invert link-invert' : '';
    return [typeClass, invertClass].join(' ');
  };

  const showIcon = !hideIcon && type !== CtfCtaStyle.SECONDARY && icon;
  return (
    <CtaWrapper
      url={url}
      page={page}
      classNames={getCtaWrapperClass()}
      onClick={onClick}
      name={name}
    >
      {showIcon && iconPosition === ICON_POSITION.left && (
        <div className={iconButtonClass}>
          <Icon
            name={icon}
            className={iconStyle}
            height={height}
            width={width}
            hasIconBorder={hasIconBorder}
          />
        </div>
      )}
      <Text tag="button" style={iconTextClass}>
        {name}
      </Text>
      {showIcon && iconPosition === ICON_POSITION.right && (
        <div className={iconButtonClass}>
          <Icon
            name={icon}
            className={iconStyle}
            width={width}
            height={height}
            hasIconBorder={hasIconBorder}
          />
        </div>
      )}
    </CtaWrapper>
  );
}

Cta.propTypes = {
  url: PropTypes.string,
  height: PropTypes.number,
  page: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.string,
  iconStyle: PropTypes.string,
  iconPosition: PropTypes.string,
  invert: PropTypes.bool,
  style: PropTypes.string,
  width: PropTypes.number,
  onClick: PropTypes.func,
};

Cta.defaultProps = {
  type: 'Primary',
  iconPosition: ICON_POSITION.right,
  iconStyle: '',
  invert: false,
};

// @TODO: find the better structure for Ctf Component
function CtfCta({
  entries,
  field,
  invert,
  noWrap,
  hideIcon,
  onClick,
  className,
}) {
  const cta = field ? getContent(field.sys?.id, entries) : null;

  if (!cta) return null;

  const {
    name,
    link,
    page: pageField,
    icon,
    style: type,
    hasIconBorder,
  } = cta.fields;
  const page = pageField
    ? getContent(pageField.sys.id, entries)?.fields?.slug
    : null;

  const iconProps = {
    hasIconBorder,
    height: 15,
    hideIcon,
    name: icon,
    width: 15,
    style: 'text-violet-bright dd-kr:text-actual-dd-gold dd-en:text-actual-dd-gold',
  };

  const buttonProps = {
    name,
    type,
    style: `${className} ${noWrap ? 'w-max' : 'w-fit'}`,
    invert,
  };

  const linkProps = {
    url: link,
    page: page,
  };

  return (
    <Cta
      iconProps={iconProps}
      buttonProps={buttonProps}
      linkProps={linkProps}
      onClick={onClick}
    />
  );
}

CtfCta.propTypes = {
  className: PropTypes.string,
  entries: PropTypes.array,
  field: PropTypes.any,
  invert: PropTypes.bool,
  noWrap: PropTypes.bool,
  type: PropTypes.string,
  onClick: PropTypes.func,
};

CtfCta.defaultProps = {
  className: '',
  entries: [],
  invert: false,
  noWrap: false,
  type: 'custom',
};

export default CtfCta;
